export default [
  {
    header: 'Ana Sayfa',
    route: 'manage-home',
    icon: 'HomeIcon',
  },
  {
    header: 'Müşteri Yönetim',
    icon: 'UserIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'manage-kullanici',
      },
      {
        title: 'Müşteriler',
        route: 'manage-musteri',
      },

      {
        title: 'Müşteri Detay',
        route: 'manage-musteri-save',
        hidden: true,
      },
      {
        title: 'Kullanıcı Detay',
        route: 'manage-kullanici-save',
        hidden: true,
      },
    ],
  },

  // urun
  {
    header: 'Ürün Yönetim',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Tüm Ürünler',
        route: 'manage-urun',
      },
      {
        title: 'Ürün Düzenleme',
        route: 'manage-urun-detay',
        hidden: true,
      },
    ],
  },

  // Siparis
  {
    header: 'Teklif Yönetim',
    icon: 'BookIcon',
    children: [
      {
        title: 'Teklifler',
        route: 'manage-teklif',
      },
      {
        title: 'Kapanan Teklifler',
        route: 'manage-teklif-kapanan',
      },

      {
        title: 'Teklif Detay İşlemleri',
        route: 'management-teklif-islem',
        hidden: true,
      },
    ],
  },

  // Siparis
  {
    header: 'Sipariş Yönetim',
    icon: 'GridIcon',
    children: [
      {
        title: 'Tüm Sipariş',
        route: 'manage-siparis',
      },

      {
        title: 'Arşiv',
        route: 'manage-siparis-arsiv',
      },

      {
        title: 'Sipariş Detay',
        route: 'manage-siparis-detay',
        hidden: true,
      },

      {
        title: 'Sipariş Arşiv Detay',
        route: 'manage-siparis-arsiv-detay',
        hidden: true,
      },
    ],
  },

  // Cari Ekstra
  {
    header: 'Cari Ekstre',
    icon: 'FileTextIcon',
    route: 'manage-cariekstra',
  },

  // Ödemeler
  {
    title: 'Ödemeler',
    icon: 'CreditCardIcon',
    route: 'manage-payments',
  },

  // Lisans Sifre Bilgi
  {
    header: 'Lisans Yönetim',
    icon: 'KeyIcon',
    route: 'manage-lisanssifrebilgi',
  },
  
  // DBFirmaInfo
  {
    title: 'Firma Veritabanı',
    icon: 'DatabaseIcon',
    route: 'manage-dbfirmainfo',
  },

  // AppAuthForm
  {
    title: 'Loglar',
    icon: 'ListIcon',
    route: 'manage-logs',
  },

  // Tanim
  {
    header: 'Tanım Yönetim',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Genel Ayarlar',
        route: 'manage-genelayar',
      },
      {
        title: 'Sözleşmeler',
        route: 'manage-sozlesme',
      },
      {
        title: 'Vergi Daire Listesi',
        route: 'manage-vergidaire',
      },
      {
        title: 'Adresler',
        route: 'manage-adres',
      },
      {
        title: 'Banka Bilgi Listesi',
        route: 'manage-bankabilgi',
      },
      {
        title: 'Ürün Grup Listesi',
        route: 'manage-urungrup',
      },
      {
        title: 'Teklif Açıklama Listesi',
        route: 'manage-teklifaciklama',
      },
      {
        title: 'Satış Personel Listesi',
        route: 'manage-satispersonel',
      },

      {
        title: 'Vergi Daire Ekleme',
        route: 'manage-vergidaire-create',
        hidden: true,
      },
      {
        title: 'Adres Ekleme',
        route: 'manage-adres-create',
        hidden: true,
      },

      {
        title: 'Ürün Grup Düzenleme',
        route: 'manage-urungrup-save',
        hidden: true,
      },

      {
        title: 'Satış Personel Düzenleme',
        route: 'manage-satispersonel-save',
        hidden: true,
      },
    ],
  },

  // Duyuru
  {
    header: 'Duyuru Yönetim',
    icon: 'Volume2Icon',
    route: 'manage-duyuru',
  },

  // Cloud Kullanıcı
  {
    header: 'Cloud Kullanıcılar',
    icon: 'CloudIcon',
    children: [
      {
        title: 'WebApi Kullanıcıları',
        route: 'manage-webapi-kullanici',
      },
      {
        title: 'Veri Tabanı Kullanıcıları',
        route: 'manage-db-kullanici',
      },
    ],
  },

  // Referans
  {
    header: 'Website Yönetim',
    icon: 'UserIcon',
    children: [
      {
        title: 'Genel',
        route: 'manage-website-genel',
      },
      {
        title: 'Hakkımızda',
        route: 'manage-website-aboutus',
      },
      {
        title: 'Referanslar',
        route: 'manage-website-referans',
      },
      {
        title: 'Çözüm Ortakları',
        route: 'manage-website-cozumortak',
      },
      {
        title: 'Banner',
        route: 'manage-website-banner',
      },
      {
        title: 'Sayfa',
        route: 'manage-website-sayfa',
      },
      {
        title: 'İletişim',
        route: 'manage-website-address',
      },
      {
        title: 'İletişim Formları',
        route: 'manage-website-contact-form',
      },

      {
        title: 'Referans Düzenleme',
        route: 'manage-website-referans-save',
        hidden: true,
      },

      {
        title: 'Çözüm Ortak Düzenleme',
        route: 'manage-website-cozumortak-save',
        hidden: true,
      },

      {
        title: 'Banner Düzenleme',
        route: 'manage-website-banner-save',
        hidden: true,
      },

      {
        title: 'Sayfa Düzenleme',
        route: 'manage-website-sayfa-save',
        hidden: true,
      },
    ],
  },

  // Referans
  {
    header: 'Destek Yönetim',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Açık Talepler',
        route: 'manage-support-open',
      },
      {
        title: 'Kapanan Talepler',
        route: 'manage-support-closed',
      },

      {
        route: 'manage-support-detail-close',
        hidden: true,
      },

      {

        route: 'manage-support-detail-open',
        hidden: true,
      },
    ],
  },
]
